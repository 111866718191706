import { IModalData } from './../../../common/interfaces/promotions.interface';
import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPromoWrapperComponent } from './promotions/dialog-promo/dialog-promo.component';
import { ScrollToClass } from '../../../common/scrollTo.class';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends ScrollToClass {
  //TODO: Cinta promocional
  public stripPromotion: boolean = true;
  //TODO: Modal promocional
  private dialogPromo = {
    available: true,
    timeOut: 5000
  };
  private eventListeners: Function[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private dialog: MatDialog,
    private _el: ElementRef,
    private renderer: Renderer2
  ) {
    super(_el);
    this.eventListeners.push(
      this.renderer.listen('window', 'click', () => this.handleUserInteraction()),
      this.renderer.listen('window', 'scroll', () => this.handleUserInteraction()),
      this.renderer.listen('window', 'mousemove', () => this.handleUserInteraction()),
      this.renderer.listen('window', 'keydown', () => this.handleUserInteraction())
    );
  }

  handleUserInteraction() {
    this.showSnackBar();
    this.removeEventListeners(); // Detener los escuchadores después de la interacción
  }

  removeEventListeners() {
      this.eventListeners.forEach(listener => listener());
      this.eventListeners = []; // Limpiar la lista
  }

  showSnackBar(): void {
    if (isPlatformBrowser(this.platformId) && this.dialogPromo.available) {
      setTimeout(() => {
        if (this.dialogPromo.available) {
          this.openDialogPromo();
        }
      }, this.dialogPromo.timeOut);
    }
  }

  public openDialogPromo() {
    const dialogRef = this.dialog.open(DialogPromoWrapperComponent, {
      hasBackdrop: true,
      disableClose: true,
      id: 'promocionalModalCF',
      maxWidth: '',
      width: '632px',
      data: { status: false, prCode: '' }
    });

    dialogRef.afterClosed().subscribe((res: IModalData) => {
      if (res.status) {
        this.scrollToSection('price-scroll-ts');
        sessionStorage.setItem('prCode', res.prCode);
      }
    });
  }
}
